<script setup>
import { useDialog } from "@/composables/useDialog";

defineOptions({
  inheritAttrs: false,
});

const optionsDefaults = {
  headerClass: ".dialog-header",
  footerClass: ".dialog-footer",
  contentClass: ".dialog-content",
};

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(["update:modelValue", "onBeforeOpen", "onAfterOpen"]);

const slots = useSlots();
const attrs = useAttrs();
const dialogHtmlElem = ref(null);

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});

const mounted = ref(false);

const optionsComputed = computed(() => ({
  maxWidth: hasProp(props, "options.maxWidth") ? props.options?.maxWidth : "100%",
  width: hasProp(props, "options.width") ? props.options?.width : "auto",
  height: hasProp(props, "options.height") ? props.options?.height : "auto",
  maxHeight: hasProp(props, "options.maxHeight") ? props.options?.maxHeight : "auto",
  // headerHeight: slots.header ? `calc(100% - ${htmlHeaderEl.value?.clientHeight}px)` : "auto",
}));

function close() {
  emit("update:modelValue", false);
}

useDialog(dialog);

provide("dialog", dialog);

function watchProps() {
  const mutationObserver = new MutationObserver(handleContentChanged);
  mutationObserver.observe(dialogHtmlElem.value, { childList: true, subtree: true });

  function handleContentChanged() {
    setTimeout(() => updateUI(), 10);
  }
  setTimeout(() => updateUI(), 10);
}

async function updateUI() {
  const dialogElem = dialogHtmlElem.value.querySelector(".dialog");
  if (!dialogElem) return;

  const options = { ...props.options, ...optionsDefaults };

  const header = dialogElem.querySelector(options?.headerClass);
  const footer = dialogElem.querySelector(options?.footerClass);
  const content = dialogElem.querySelector(options?.contentClass);
  const contentSlot = dialogElem.querySelector(".content-slot");

  if (header) header.style.boxSizing = "border-box";
  if (content) content.style.boxSizing = "border-box";
  if (footer) footer.style.boxSizing = "border-box";

  const dialogContentHeight =
    dialogElem?.offsetHeight - (header?.offsetHeight || 0) - (footer?.offsetHeight || 0);

  if (content) content.style.height = dialogContentHeight + "px";

  const contentHeight = content?.offsetHeight;
  if (content?.scrollHeight <= contentHeight) {
    content.style.height = "auto";
  }
}

onMounted(() => {
  mounted.value = true;
  watchProps();
});
</script>

<template>
  <teleport to="body">
    <div v-bind="attrs" class="wrapper" :ref="(el) => (dialogHtmlElem = el)">
      <!-- <transition
        name="dialog"
        @before-enter="emit('onBeforeOpen')"
        @after-leave="emit('onAfterOpen')"
      > -->
      <div v-if="dialog && mounted" class="dialog" :style="options?.style">
        <div class="content-slot">
          <slot></slot>
        </div>
      </div>
      <!-- </transition> -->

      <transition name="backdrop">
        <div
          v-if="dialog && mounted"
          class="backdrop no-doc-scroll"
          @click="close()"
        ></div>
      </transition>
    </div>
  </teleport>
</template>

<style scoped>
@import "./css/effects.css";

.wrapper {
  position: fixed;
  z-index: 110;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  background: var(--background, #fff);
  transform: translate3d(-50%, -50%, 0);

  overscroll-behavior: contain;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);

  /* /* display: flex; */
  z-index: 10;
  width: v-bind("optionsComputed.width");
  max-width: v-bind("optionsComputed.maxWidth");
  height: v-bind("optionsComputed.height");
  max-height: v-bind("optionsComputed.maxHeight");
  box-sizing: border-box;
}

.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #202124;
  opacity: 0.3;
}

.content-wrapper {
  width: 100%;
  /* height: auto; */
  /* height: v-bind("optionsComputed.headerHeight"); */

  /* display: inline-flex;
  flex-direction: column;
  flex: 1; */
  color: var(--text-form-input-color) !important;
}

.header {
  width: 100%;
  flex-shrink: 0;
}

.footer {
  width: 100%;
  margin-top: auto;
}

/* effects */

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(0.3);
}

.dialog-enter-active,
.dialog-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* bottom-sheet */

[bottom-sheet] .dialog {
  width: 100vw;
  top: 100vh;
  transform: translate3d(-50%, -100%, 0);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  opacity: 1;
}

[bottom-sheet] .dialog-enter-from,
[bottom-sheet] .dialog-leave-to {
  transform: translate3d(-50%, 0%, 0);
}

[bottom-sheet] .dialog-enter-active,
[bottom-sheet] .dialog-leave-active {
  /* transition: transform .2s cubic-bezier(0.25, 0.8, 0.25, 1); */
}

/* left-sheet */

[left-sheet] .dialog {
  width: auto;
  left: 0;
  top: 0;
  height: 100%;
  transform: translate3d(0%, 0%, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  opacity: 1;
}

[left-sheet] .dialog-enter-from,
[left-sheet] .dialog-leave-to {
  transform: translate3d(-100%, 0%, 0);
}

[left-sheet] .dialog-enter-active,
[left-sheet] .dialog-leave-active {
  /* opacity: 0.5; */
  /* transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); */
}

/* fullscreen */
[fullscreen] .dialog {
  min-width: 100%;
  max-width: 100%;
  background: var(--background, #fff);
  border-radius: 0;
  width: 100%;
  height: 100%;
}

[fullscreen] .overlay,
[fullscreen] .active .overlay {
  display: none;
}

/* dense */

[dense] .dialog {
  min-width: 100%;
  max-width: 100%;
  min-width: var(--screen-min-width);
  max-width: var(--screen-max-width);
  background: var(--background);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 95%;
}

/* [dense] .content{
  border-radius: 10px;
} */

[dense] .overlay,
[dense] .active .overlay {
  display: none;
}
</style>
